@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  background: black !important;
  margin: 0;
  font-family: "Aeonik", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Regular.woff") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

#root {
  background: black !important;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Enable scrolling */
.no-scrollbar {
  overflow-y: scroll;
  /* or 'auto' */
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(220, 224, 217, 0.5);
  border-radius: 1000px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(220, 224, 217, 0.8);

}
